import request from '@/util/request';
import qs from "qs";

export function get() {
    return request({
        url: "/miniprogram",
        method: "get"
    });
}

export function updateEmployeeMiniprogram(id) {
    return request({
        url: "/miniprogram/updateEmployeeMiniprogram",
        method: "post",
        data: qs.stringify({ id })
    });
}

export function updateUserMiniprogram(id) {
    return request({
        url: "/miniprogram/updateUserMiniprogram",
        method: "post",
        data: qs.stringify({ id })
    });
}

export function publishEmployee() {
    return request({
        url: "/miniprogram/publishEmployee",
        method: "post"
    });
}

export function publishUser() {
    return request({
        url: "/miniprogram/publishUser",
        method: "post"
    });
}

export function resetEmployee() {
    return request({
        url: "/miniprogram/resetEmployee",
        method: "post"
    });
}

export function resetUser() {
    return request({
        url: "/miniprogram/resetUser",
        method: "post"
    });
}