<template>
    <div>
        <TitleBar>小程序设置</TitleBar>
        <div class="main-container">
            <h1 class="remark">注：每个小程序设置次数有限（50次/月），请谨慎操作</h1>
            <div class="data-panel">
                <h1 class="section-title">销售端小程序</h1>
                <div class="line">
                    <template v-if="miniprogramData && miniprogramData.employee">
                        <h2>{{ miniprogramData.employee.name }}</h2>
                        <button class="edit" @click="editEmployee"><i class="nazaio-iconfont nz-edit-square" /></button>
                        <Tag class="tag" :color="miniprogramData.employee.status | colorFilter">{{
                            miniprogramData.employee.status | statusFilter
                        }}</Tag>
                        <h4 v-if="miniprogramData.employee.status === -1">{{ miniprogramData.employee.message }}</h4>
                        <Button class="publish" type="primary" v-if="miniprogramData.employee.status === 0" @click="publish('employee')">发布小程序</Button>
                    </template>
                    <template v-else>
                        <h2>未设置</h2>
                        <button class="edit" @click="editEmployee"><i class="nazaio-iconfont nz-edit-square" /></button>
                    </template>
                </div>
                <h1 class="section-title" style="margin-top: 50px">用户端小程序</h1>
                <div class="line">
                    <template v-if="miniprogramData && miniprogramData.user">
                        <h2>{{ miniprogramData.user.name }}</h2>
                        <button class="edit" @click="editUser"><i class="nazaio-iconfont nz-edit-square" /></button>
                        <Tag class="tag" :color="miniprogramData.user.status | colorFilter">{{
                            miniprogramData.user.status | statusFilter
                        }}</Tag>
                        <h4 v-if="miniprogramData.user.status === -1">{{ miniprogramData.user.message }}</h4>
                        <Button class="publish" type="primary" v-if="miniprogramData.user.status === 0" @click="publish('user')">发布小程序</Button>
                    </template>
                    <template v-else>
                        <h2>未设置</h2>
                        <button class="edit" @click="editUser"><i class="nazaio-iconfont nz-edit-square" /></button>
                    </template>
                </div>
            </div>
        </div>
        <Modal v-model="showDialog" width="400" :title="dialogTitle" class="tag-dialog">
            <Form :model="form" label-position="left" style="padding: 0 8px">
                <FormItem label="小程序" required>
                    <Select v-model="form.id" placeholder="请选择小程序" style="width: 240px">
                        <Option v-for="item in miniprogramList" :value="item.id" :key="item.id">{{ item.nickname }}</Option>
                    </Select>
                </FormItem>
            </Form>
            <div slot="footer" class="btn-list">
                <button class="stop-use" @click="resetMiniprogram">重置</button>
                <Button @click="showDialog = false">取消</Button>
                <Button type="primary" @click="save">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import {
    get,
    updateEmployeeMiniprogram,
    updateUserMiniprogram,
    resetEmployee,
    resetUser,
    publishEmployee,
    publishUser,
} from "@/api/miniprogram";
import { getMiniprogramList } from "@/api/channel";

export default {
    components: {
        TitleBar,
    },
    created() {
        getMiniprogramList().then((response) => {
            this.miniprogramList = response;
        });
        this.get();
    },
    data() {
        return {
            miniprogramData: null,
            miniprogramList: [],
            showDialog: false,
            editing: "",
            form: {
                id: 0,
            },
            newTagName: "",
            editTag: false,
        };
    },
    filters: {
        statusFilter(status) {
            switch (status) {
                case -1:
                    return "状态异常";
                case 0:
                    return "审核成功";
                case 1:
                    return "审核被拒绝，请联系管理员";
                case 2:
                    return "审核中";
                case 3:
                    return "已撤回";
                default:
                    return "未知状态";
            }
        },
        colorFilter(status) {
            switch (status) {
                case -1:
                    return "error";
                case 0:
                    return "success";
                case 1:
                    return "warning";
                case 2:
                    return "green";
                case 3:
                    return "orange";
                default:
                    return "magenta";
            }
        },
    },
    computed: {
        dialogTitle() {
            if (this.editing === "employee") {
                return "设置销售端小程序";
            }
            return "设置用户端小程序";
        },
    },
    methods: {
        get() {
            get().then((response) => {
                this.miniprogramData = response;
            });
        },
        editEmployee() {
            this.editing = "employee";
            this.form.id = 0;
            if (this.miniprogramData.employee) {
                this.form.id = this.miniprogramData.employee.id;
            }
            this.showDialog = true;
        },
        editUser() {
            this.editing = "user";
            this.form.id = 0;
            if (this.miniprogramData.user) {
                this.form.id = this.miniprogramData.user.id;
            }
            this.showDialog = true;
        },
        save() {
            if (!this.form.id) {
                this.$Message.error("请选择小程序！");
                return;
            }
            let saveFunc;
            if (this.editing === "employee") {
                saveFunc = updateEmployeeMiniprogram;
            } else {
                saveFunc = updateUserMiniprogram;
            }
            saveFunc(this.form.id).then(() => {
                this.$Message.success("保存成功！");
                this.showDialog = false;
                this.get();
            });
        },
        publish(type) {
            if (type === "user") {
                publishUser().then(() => {
                    this.$Message.success("发布成功！");
                    this.get();
                });
            } else if (type === "employee") {
                publishEmployee().then(() => {
                    this.$Message.success("发布成功！");
                    this.get();
                });
            }
        },
        resetMiniprogram() {
            this.$Modal.confirm({
                title: "确定要重置小程序设置吗？",
                content: "<p>重置后相关功能将无法使用，如需使用请重新设置。</p>",
                onOk: () => {
                    let resetFunc;
                    if (this.editing === "employee") {
                        resetFunc = resetEmployee;
                    } else {
                        resetFunc = resetUser;
                    }
                    resetFunc().then(() => {
                        this.$Message.success("重置成功！");
                        this.showDialog = false;
                        this.get();
                    });
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.main-container {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
        border-radius: 3px;
    }
}

.remark {
    font-size: 16px;
    color: #0a2a4c;
    height: 24px;
    line-height: 24px;
}

.section-title {
    font-size: 16px;
    color: #0a2a4c;
    height: 24px;
    line-height: 24px;
}

.data-panel {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;
}

.line {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.line h2 {
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    font-weight: normal;
    margin: 0;
    color: #0a2a4c;
}

.line .edit {
    color: #0a2a4c;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-left: 10px;
}

.line .publish {
    margin-left: 20px;
}

.line .tag {
    margin-left: 20px;
}

.line h4 {
    height: 32px;
    line-height: 32px;
    color: #ff0000;
    font-size: 14px;
    margin: 0 0 0 20px;
}

.stop-use {
    float: left;
    background-color: transparent;
    color: #ed4014;
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;
    height: 32px;
    line-height: 32px;
}
</style>